import React, { Component } from 'react';
import styled from "styled-components";
import './App.css';
import {Window, Terminal, Line} from "termynal-react";
import JType from "jatrix-react/build/index";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 20px;
`;

class App extends Component {
  render() {
    return (
      <div className="App">
        <Box>
          <h1>
            <JType fps={10}>
              Hi, I'm ian fabs
            </JType>
          </h1>
          <h2>A developer on the world-wide web!</h2>
          <hr style={{width: "80vw", margin: "10px 0"}}/>
          <h3>try installing some of my npm packages!</h3>
          <Window style={{width: "50vw"}}>
            <Terminal>
              <Line type="input">npm i jatrix-react termynal-react jraph rholor</Line>
              <Line type="progress"/>
            </Terminal>
          </Window>
        </Box>
      </div>
    );
  }
}

export default App;
